import React, { Fragment, useEffect } from "react";
import cardBackground from "../../assets/cardbackground.png";

export default function Terms() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <div class="bg-gradient-to-b h-fit from-white to-gray-100 bg-gradient-to-r font-figtree">
        <div class="absolute inset-x-0 top-0 z-5 opacity-20 flex">
          <img class=" w-full" src={cardBackground} alt="" />
        </div>
        <div class="mx-auto max-w-7xl py-16 px-4 sm:py-24 sm:px-6 lg:px-8 relative">
          <div class="text-center">
            {/* <h2 class="text-lg font-semibold text-indigo-600"></h2> */}
            <p class="mt-1 text-4xl font-bold tracking-tight text-green-600 sm:text-5xl lg:text-6xl">
              Terms and Conditions
            </p>
            <p class="mx-auto mt-5 max-w-xl text-xl text-gray-700">
              If you have an issue with these terms, you can always{" "}
              <a
                className="text-green-500"
                href="mailto:support@tapngogh.com?subject=ENQURIES ABOUT TAPNGO"
              >
                send us an email
              </a>{" "}
              with your enquiry.
            </p>
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-7xl p-20 mt-7">
        <h1 className="font-bold text-2xl text-green-600">
          Terms and Conditions
        </h1>

        <p>Last updated: September 09, 2024</p>
        <p>
          Please read these terms and conditions carefully before using Our
          Service.
        </p>

        <h2 class="text-green-600">Interpretation and Definitions</h2>

        <h3 class="text-green-600">Interpretation</h3>
        <p>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </p>

        <h3 class="text-green-600">Definitions</h3>
        <p>For the purposes of these Terms and Conditions:</p>
        <ul>
          <li>
            <strong>Application</strong> means the software program provided by
            the Company downloaded by You on any electronic device, named Tap
            and Go Ghana.
          </li>
          <li>
            <strong>Application Store</strong> means the digital distribution
            service operated and developed by Apple Inc. (Apple App Store) or
            Google Inc. (Google Play Store) in which the Application has been
            downloaded.
          </li>
          <li>
            <strong>Affiliate</strong> means an entity that controls, is
            controlled by or is under common control with a party, where
            "control" means ownership of 50% or more of the shares, equity
            interest, or other securities entitled to vote for election of
            directors or other managing authority.
          </li>
          <li>
            <strong>Country</strong> refers to Ghana.
          </li>
          <li>
            <strong>Company</strong> (referred to as either "the Company", "We",
            "Us" or "Our" in this Agreement) refers to TFG, Accra, Ghana.
          </li>
          <li>
            <strong>Device</strong> means any device that can access the
            Service, such as a computer, cellphone, or digital tablet.
          </li>
          <li>
            <strong>Service</strong> refers to the Application.
          </li>
          <li>
            <strong>Terms and Conditions</strong> (also referred to as "Terms")
            mean these Terms and Conditions that form the entire agreement
            between You and the Company regarding the use of the Service. This
            Terms and Conditions agreement has been created with the help of the
            TermsFeed Terms and Conditions Generator.
          </li>
          <li>
            <strong>Third-party Social Media Service</strong> means any services
            or content (including data, information, products, or services)
            provided by a third party that may be displayed, included, or made
            available by the Service.
          </li>
          <li>
            <strong>You</strong> means the individual accessing or using the
            Service, or the company, or other legal entity on behalf of which
            such individual is accessing or using the Service, as applicable.
          </li>
        </ul>

        <h2 class="text-green-600">Acknowledgment</h2>
        <p>
          These are the Terms and Conditions governing the use of this Service
          and the agreement that operates between You and the Company. These
          Terms and Conditions set out the rights and obligations of all users
          regarding the use of the Service.
        </p>
        <p>
          Your access to and use of the Service is conditioned on Your
          acceptance of and compliance with these Terms and Conditions. These
          Terms and Conditions apply to all visitors, users, and others who
          access or use the Service.
        </p>
        <p>
          By accessing or using the Service, You agree to be bound by these
          Terms and Conditions. If You disagree with any part of these Terms and
          Conditions, then You may not access the Service.
        </p>
        <p>
          You represent that you are over the age of 18. The Company does not
          permit those under 18 to use the Service.
        </p>
        <p>
          Your access to and use of the Service is also conditioned on Your
          acceptance of and compliance with the Privacy Policy of the Company.
          Our Privacy Policy describes Our policies and procedures on the
          collection, use, and disclosure of Your personal information when You
          use the Application or the Website and tells You about Your privacy
          rights and how the law protects You. Please read Our Privacy Policy
          carefully before using Our Service.
        </p>

        <h2 class="text-green-600">Background Location for Android Users</h2>
        <p>
          The Application may request access to background location data for
          Android users. By enabling location services on your Device, you allow
          the Company to collect location data while the Application is running
          in the background. This enables us to gather real-time driver location
          information, helping us match drivers with nearby trip requests,
          improving the efficiency of trip assignments.
        </p>
        <p>
          This location data will only be used to provide the Service, including
          monitoring possible trip requests and optimizing driver location
          tracking. By using the Service and enabling location access, you
          consent to the collection, use, and storage of this data.
        </p>

        <h2 class="text-green-600">Links to Other Websites</h2>
        <p>
          Our Service may contain links to third-party websites or services that
          are not owned or controlled by the Company.
        </p>
        <p>
          The Company has no control over and assumes no responsibility for the
          content, privacy policies, or practices of any third-party websites or
          services. You further acknowledge and agree that the Company shall not
          be responsible or liable, directly or indirectly, for any damage or
          loss caused or alleged to be caused by or in connection with the use
          of or reliance on any such content, goods, or services available on or
          through any such websites or services.
        </p>
        <p>
          We strongly advise You to read the terms and conditions and privacy
          policies of any third-party websites or services that You visit.
        </p>

        <h2 class="text-green-600">Termination</h2>
        <p>
          We may terminate or suspend Your access immediately, without prior
          notice or liability, for any reason whatsoever, including without
          limitation if You breach these Terms and Conditions.
        </p>
        <p>
          Upon termination, Your right to use the Service will cease
          immediately.
        </p>

        <h2 class="text-green-600">Limitation of Liability</h2>
        <p>
          Notwithstanding any damages that You might incur, the entire liability
          of the Company and any of its suppliers under any provision of these
          Terms and Your exclusive remedy for all of the foregoing shall be
          limited to the amount actually paid by You through the Service or 100
          USD if You haven't purchased anything through the Service.
        </p>
        <p>
          To the maximum extent permitted by applicable law, in no event shall
          the Company or its suppliers be liable for any special, incidental,
          indirect, or consequential damages whatsoever (including, but not
          limited to, damages for loss of profits, loss of data or other
          information, for business interruption, for personal injury, or loss
          of privacy arising out of or in any way related to the use of or
          inability to use the Service), even if the Company or any supplier has
          been advised of the possibility of such damages and even if the remedy
          fails of its essential purpose.
        </p>

        <h2 class="text-green-600">Governing Law</h2>
        <p>
          The laws of the Country, excluding its conflicts of law rules, shall
          govern these Terms and Your use of the Service. Your use of the
          Application may also be subject to other local, state, national, or
          international laws.
        </p>

        <h2 class="text-green-600">Changes to These Terms and Conditions</h2>
        <p>
          We reserve the right, at Our sole discretion, to modify or replace
          these Terms at any time. If a revision is material, We will make
          reasonable efforts to provide at least 30 days' notice prior to any
          new terms taking effect. What constitutes a material change will be
          determined at Our sole discretion.
        </p>
        <p>
          By continuing to access or use Our Service after those revisions
          become effective, You agree to be bound by the revised terms. If You
          do not agree to the new terms, in whole or in part, please stop using
          the website and the Service.
        </p>
      </div>
    </Fragment>
  );
}
